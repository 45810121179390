import React from 'react';

import { LandingPageWrapper } from 'components/global/LandingPageWrapper';
import { Footer } from 'components/global/Footer';
import { Head } from '../Homepage/components/Head';
import { SEO, SEO_CORE_CONTENT } from 'components/global/SEO';
import { Nav } from 'components/global/Nav';

import {
  BeforeAfterPop,
  POP_REDESIGN_BEFORE_AFTER_CONTENT,
} from 'components/modules/pop-redesign/BeforeAfterPop';
import {
  HeroPop,
  POP_REDESIGN_HERO_CONTENT,
} from 'components/modules/pop-redesign/HeroPop';
import {
  IngredientsPop,
  POP_REDESIGN_INGREDIENTS_CONTENT,
} from 'components/modules/pop-redesign/IngredientsPop';
import {
  ProductsPop,
  POP_REDESIGN_PRODUCTS_CONTENT,
} from 'components/modules/pop-redesign/ProductsPop';
import {
  StepsPop,
  POP_REDESIGN_STEPS_CONTENT,
} from 'components/modules/pop-redesign/StepsPop';
import {
  FooterDisclaimerPop,
  POP_FOOTER_DISCLAIMER_CONTENT,
} from 'components/modules/pop-redesign/FooterDisclaimerPop';
import { getBannerPropsFromLdFlagValue } from 'components/global/Nav/components';
import {
  POP_REDESIGN_PRODUCT_HIGHLIGHTS_CONTENT,
  ProductHighlights,
} from 'components/modules/ProductHighlights';
import { useFlag } from 'utils/featureFlagsAndMetrics';

interface HomepagePropsType {
  isMarketingSafe?: boolean;
}

export const HomepagePop = ({ isMarketingSafe = false }: HomepagePropsType) => {
  const curologySiteIntroOfferBannerCopy: string | undefined = useFlag(
    'curology-site-intro-offer-banner-copy',
  );

  return (
    <LandingPageWrapper>
      <Head />
      <SEO {...SEO_CORE_CONTENT} />
      <Nav
        bannerProps={[
          getBannerPropsFromLdFlagValue(curologySiteIntroOfferBannerCopy),
        ]}
        isMarketingSafe={isMarketingSafe}
      />
      <HeroPop {...POP_REDESIGN_HERO_CONTENT} />
      <ProductHighlights {...POP_REDESIGN_PRODUCT_HIGHLIGHTS_CONTENT} />
      <StepsPop {...POP_REDESIGN_STEPS_CONTENT} />
      <BeforeAfterPop {...POP_REDESIGN_BEFORE_AFTER_CONTENT} />
      <IngredientsPop {...POP_REDESIGN_INGREDIENTS_CONTENT} />
      <ProductsPop {...POP_REDESIGN_PRODUCTS_CONTENT} />
      <Footer
        isMarketingSafe={isMarketingSafe}
        disclaimer={<FooterDisclaimerPop {...POP_FOOTER_DISCLAIMER_CONTENT} />}
      />
    </LandingPageWrapper>
  );
};
